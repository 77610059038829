<template>
  <div class="layout-root">
    <div class="content">
      <div class="page">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'DefaultLayout',
  slots: Object as SlotsType<{
    default: {}
  }>
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  z-index: 1;
  padding: 0;
  width: calc(100% - #{$side-width});
  margin-left: $side-width;
}
</style>
